export const Kits = (props) => {
    return (
      <div id="kits">
        <div className="container">
          <div className='section-title'>
            <h2>Zestawy AgroNAV</h2>
            <p>
              W naszej ofercie znajduje się Zestaw Podstawowy i Rozszerzony nawigacji AgroNAV.
            </p>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/sets/basic_set_topview_2_small_shadow.png" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="kits-text">
                <h3>Zestawy podstawowy</h3>
                <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
                <h4>Skład zestawu</h4>
                <div className="list-style">
                    <ul>
                      {props.data
                        ? props.data.Kit1.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <img src="img/prices/price_basic.png" className="img-prices" alt="" />{" "}
          </div>

          <div className='row'>
            <a href='#contact' className='btn btn-custom btn-lg page-scroll kits-btn'>
              Dowiedz się więcej
            </a>{' '}
          </div>

          <div className="row sets">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/sets/advanced_set_topview_2_small_shadow.png" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="kits-text">
                <h3>Zestawy rozszerzony</h3>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                <h4>Skład zestawu</h4>
                <div className="list-style">
                    <ul>
                      {props.data
                        ? props.data.Kit2.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <img src="img/prices/price_extended.png" className="img-prices" alt="" />{" "}
          </div>

          <div className='row'>
            <a href='#contact' className='btn btn-custom btn-lg page-scroll kits-btn'>
              Dowiedz się więcej
            </a>{' '}
          </div>

          <div className="col-xs-12 col-md-12">
              <div className="kits-text">
                <h3>Wyposażenie opcjonalne</h3>
                <p>Dodatkowo poza zestawami można nabyć:</p>
                <div className="list-style">
                    <ul>
                      <li>moduł żyroskopowy <b>AgroIMU</b> - 1200zł,</li>
                      <li>moduł automatycznego sterowania sekcjami <b>AgroSwitch</b> - przygotowywany pod indywidualne potrzebny, cena zależna od konkretnej realizacji,</li>
                      <li>antena GNSS - 400zł,</li>
                      <li>kamera (opcjonalne wyposażenie do zestawu z tabletem 10") - 300zł.</li>
                    </ul>
                </div>
                <p>Podane ceny są cenami netto.</p>
              </div>
            </div>

        </div>
      </div>
    );
  };